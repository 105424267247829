@import url('https://fonts.googleapis.com/css?family=Space+Mono');

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../assets/fonts/EuclidCircularB-Light.woff2') format('woff2'),
    url('../assets/fonts/EuclidCircularB-Light.woff') format('woff'),
    url('../assets/fonts/EuclidCircularB-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../assets/fonts/EuclidCircularB-Regular.woff2') format('woff2'),
    url('../assets/fonts/EuclidCircularB-Regular.woff') format('woff'),
    url('../assets/fonts/EuclidCircularB-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../assets/fonts/EuclidCircularB-Medium.woff2') format('woff2'),
    url('../assets/fonts/EuclidCircularB-Medium.woff') format('woff'),
    url('../assets/fonts/EuclidCircularB-Medium.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../assets/fonts/EuclidCircularB-Semibold.woff2') format('woff2'),
    url('../assets/fonts/EuclidCircularB-Semibold.woff') format('woff'),
    url('../assets/fonts/EuclidCircularB-Semibold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../assets/fonts/EuclidCircularB-Bold.woff2') format('woff2'),
    url('../assets/fonts/EuclidCircularB-Bold.woff') format('woff'),
    url('../assets/fonts/EuclidCircularB-Bold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url('../assets/fonts/TiemposText-Regular.woff2') format('woff2'),
    url('../assets/fonts/TiemposText-Regular.woff') format('woff'),
    url('../assets/fonts/TiemposText-Regular.ttf') format('truetype');
  font-weight: 400;
}

:root {
  font-family: EuclidCircularB, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
:before,
:after {
  box-sizing: inherit;
}

img {
  display: block;
}

caption,
th,
td {
  text-align: inherit;
}

html,
body,
th,
td {
  padding: 0;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
dl,
dd,
figure,
blockquote,
hr,
input,
button,
select,
textarea {
  margin: 0;
}
tt,
code,
kbd,
samp,
pre {
  font-family: code, monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
em,
cite,
strong,
small,
input,
button,
select,
text-area {
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

table {
  border-collapse: collapse;
}

u,
s,
strike,
del,
ins {
  text-decoration: inherit;
}

input,
button,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
}

[type='date'],
[type='email'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='url'],
[type='text'],
[type='button'],
[type='submit'],
[type='reset'],
button,
textarea {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type='button'],
[type='submit'],
[type='reset'],
button {
  cursor: pointer;
  text-align: inherit;
}

[type='button']:focus,
[type='submit']:focus,
[type='reset']:focus,
button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: unset;
}
